.footer-dropdown {
  display: flex;
  flex-direction: column;
  gap: rem(21);

  @include mediaBigDesktop {
    gap: big(21);
  }

  &__title {
    color: var(--text-light-tertiary);
  }

  &__icon {
    width: rem(16);
    height: rem(16);
    display: flex;
    flex-shrink: 0;

    fill: var(--bg-dark);

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }
  }

  &__list {
    margin: 0;

    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(10);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__link {
    position: relative;

    color: var(--text-light-primary);
    font: var(--font-body-XS);
    text-decoration: none;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include hover {
      color: var(--accent-light-secondary);
    }
  }

  &_column {
    & .footer-dropdown {
      &__list {
        display: block;
        columns: 2;
        column-gap: rem(40);

        @include mediaBigDesktop {
          column-gap: big(40);
        }

        @include mediaLaptop {
          column-gap: rem(20);
        }
      }

      &__item {
        margin-bottom: rem(10);

        padding-right: rem(20);

        @include mediaBigDesktop {
          margin-bottom: big(10);

          padding-right: big(20);
        }
      }
    }
  }

  @include mediaTablet {
    position: relative;

    padding: rem(19) 0 rem(20);
    width: 100%;

    border-top: solid 1px var(--stroke-light-secondary);

    &>input[type=checkbox] {
      &:checked {
        &~.footer-dropdown {
          &__title {
            &::after {
              transform: rotate(180deg);
            }
          }

          &__content {
            display: flex;
          }
        }
      }
    }

    &_column {
      & .footer-dropdown {
        &__list {
          display: flex;
          flex-direction: column;
          gap: rem(10);
        }

        &__item {
          margin: 0;

          padding: 0;
        }
      }
    }

    &__content {
      display: none;
    }

    &__title {
      position: relative;

      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: rem(10);

      cursor: pointer;

      &::before {
        @include pseudo(15)
      }

      &::after {
        content: "";
        position: relative;

        width: rem(20);
        height: rem(20);
        display: flex;
        flex-direction: row;

        background-color: var(--bg-white);
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.1667 7.50034C14.3315 7.50038 14.4926 7.54927 14.6296 7.64084C14.7666 7.73241 14.8734 7.86255 14.9365 8.0148C14.9995 8.16705 15.016 8.33457 14.9839 8.4962C14.9517 8.65783 14.8724 8.8063 14.7559 8.92284L10.5892 13.0895C10.433 13.2457 10.221 13.3335 10.0001 13.3335C9.7791 13.3335 9.56717 13.2457 9.4109 13.0895L5.24423 8.92284C5.12773 8.8063 5.04839 8.65783 5.01625 8.4962C4.98411 8.33457 5.00061 8.16705 5.06367 8.0148C5.12673 7.86255 5.23351 7.73241 5.37052 7.64084C5.50753 7.54927 5.66861 7.50038 5.8334 7.50034H14.1667Z' fill='white'/%3E%3C/svg%3E");
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;

        transition: transform var(--animation-timing) var(--cubic-bezier);
      }
    }

    &__link {
      font: var(--font-body-M)
    }
  }

  @include mediaMobile {
    padding: rem(15) 0 rem(14);
  }
}
