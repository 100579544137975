.contact-list {
  &__list {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    gap: rem(20);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(20);
    }
  }

  &__item,
  &__item>.link {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: rem(15);
    
    text-decoration: none;

    @include mediaBigDesktop {
      gap: big(15);
    }

    &::before {
      @include pseudo(5) {}
    }
  }

  &__item>.link {
    @include hover {
      & .contact-list {
        &__text {
          color: var(--accent-light-secondary);
        }
        &__icon {
          &>svg {
            fill: var(--accent-light-secondary);
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__text {
    color: var(--text-light-primary);
    font: var(--font-body-XS);

    transition: color var(--animation-timing) var(--cubic-bezier);
    
    @include mediaTablet {
      font: var(--font-body-M);
    }
  }

  &__title {
    color: var(--text-light-tertiary);
  }

  &__icon {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    opacity: 0.5;

    &>svg {
      position: relative;
      z-index: 1;

      width: rem(24);
      height: rem(24);

      fill: var(--text-light-tertiary);

      transition: fill var(--animation-timing) var(--cubic-bezier);

      @include mediaBigDesktop {
        width: big(24);
        height: big(24);
      }
    }
  }
}
