.made-by {
  position: relative;

  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: rem(15);

  text-decoration: none;

  user-select: none;

  @include mediaBigDesktop {
    gap: big(15);
  }

  @include hover {
    & .made-by {
      &__logo {
        fill: var(--accent-light-secondary);
      }
    }
  }

  &::before {
    @include pseudo(10) {}
  }
  
  &_st-lt {
    gap: rem(0);
    
    .made-by {
      &__logo {
        width: rem(70);
        height: rem(20);
      
        @include mediaBigDesktop {
          width: big(70);
          height: big(20);
        }
      }
    }
  }

  &__logo {
    width: rem(41);
    height: rem(15);

    fill: var(--text-light-tertiary);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(41);
      height: big(15);
    }
  }

  &__text {
    color: var(--text-light-tertiary);
    letter-spacing: rem(1.6);

    @include mediaBigDesktop {
      font: 400 #{big(16)}/150% var(--font-primary);
      letter-spacing: big(1.6);
    }
    
    @include mediaTablet {}
  }
}
