@import '../../mixins';

.footer {
  background-color: var(--footer-background);
  
  &_light {
    --bg-dark: var(--bg-light);
    --text-light-primary: var(--text-dark-primary);
    --text-light-tertiary: var(--text-dark-tertiary);
    --stroke-light-secondary: var(--stroke-dark-secondary);
    --bg-white: var(--accent-dark-primary);
  }

  &-primary {
    padding: rem(90) 0 rem(60.5);

    @include mediaBigDesktop {
      padding: big(90) 0 big(60);
    }

    @include mediaLaptop {
      padding: rem(70) 0 rem(50);
    }

    @include mediaTablet {
      padding: rem(50) 0 0;
    }

    @include mediaMobile {
      padding: rem(40) 0 0;
    }

    &__container {
      position: relative;
      
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: start;
      gap: rem(40);

      @include mediaBigDesktop {
        gap: big(40);
      }

      @include mediaLaptop {
        gap: rem(71);
      }

      @include mediaTablet {
        flex-direction: column;
        gap: rem(40);
      }

      @include mediaMobile {
        gap: rem(30);
      }
    }

    &__block {
      width: rem(260);
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      flex-shrink: 0;
      gap: rem(40);

      @include mediaDesktop {
        width: rem(388);
      }

      @include mediaBigDesktop {
        width: big(388);
        gap: big(40);
      }

      @include mediaLaptop {
        width: rem(166);
      }

      @include mediaTablet {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      @include mediaMobile {
        width: 100%;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: rem(20);
      }
    }

    &__menu {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: end;
      gap: rem(40);

      @include mediaTablet {
        flex-direction: column;
        gap: 0;
      }
    }

    & .footer-dropdown {
      width: 100%;
      max-width: rem(260);
      flex-grow: 1;

      @include mediaDesktop {
        max-width: rem(388);
      }

      @include mediaBigDesktop {
        max-width: big(388);
      }

      @include mediaLaptop {
        max-width: rem(266);
      }

      @include mediaTablet {
        max-width: unset;
      }

      &_column {
        max-width: unset;
      }
    }
  }

  &-social {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      gap: rem(7.73);
    }

    &__item.medium {
      @include mediaLaptop {
        width: rem(34);
        height: rem(34);
      }

      @include mediaTablet {
        width: rem(44);
        height: rem(44);
      }

      @include mediaMobile {
        width: rem(34);
        height: rem(34);
      }

      & .button {
        &__icon {
          @include mediaLaptop {
            width: rem(15.45);
            height: rem(15.45);
          }

          @include mediaTablet {
            width: rem(20);
            height: rem(20);
          }

          @include mediaMobile {
            width: rem(15.45);
            height: rem(15.45);
          }
        }
      }
    }
  }

  &__logo {
    & .logo {
      &__image {
        width: auto;

        @include mediaMobile {
          width: rem(90);
          height: auto;
        }
      }
    }
  }

  &-powered {
    padding: rem(40) 0;

    @include mediaBigDesktop {
      padding: big(40) 0;
    }

    @include mediaLaptop {
      padding: rem(30) 0 rem(32);
    }

    @include mediaTablet {
      padding: rem(30) 0;
    }

    @include mediaMobile {
      padding: rem(30) 0 rem(40);
    }
    
    &__block {
      &_made-by {
        display: flex;
        gap: rem(30);
      }
      
      @include mediaBigDesktop {
        gap: big(30);
      }
      
      @include mediaLaptop {
        gap: rem(10);
      }
      
      @include mediaTablet {
        flex-direction: column;
        
        .footer-powered {
          &__line {
            display: none;
          }
        }
      }
    }
    
    &__line {
      width: rem(1);  
      height: rem(24);
      
      display: block;
      
      background-color: rgba(255, 255, 255, 0.10);
      
      @include mediaBigDesktop {
        width: big(1);  
        height: big(24);
      }
      
      @include mediaLaptop {
        
      }
    }

    &__container {
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: rem(10);

      @include mediaBigDesktop {
        gap: big(10);
      }

      @include mediaMobile {
        flex-direction: column;
        align-items: start;
        justify-content: start;
      }
    }

    &__copyright {
      color: var(--text-light-primary);
      font: var(--font-body-XS);

      user-select: none;
    }
  }
}

@import "footer-dropdown", "made-by", "contact-list";
